import './css/style.css';
import './bgAnim.js';
import { loadFonts } from './font.js';
import { top } from './components/top';
import { container } from './global';

const app = () => {
  if (container) {
    container.insertAdjacentHTML('beforebegin', top); //Load Header Logo
  }
  loadFonts(); //Load Fonts
};

app(); //Run App
