const boxes = /*html*/ `
<div class="fixed top-0 left-0 w-full h-full -z-10 overflow-hidden">
    <ul class="circles">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
    </ul>
</div>
`;
document.body.insertAdjacentHTML('beforeend', boxes);
