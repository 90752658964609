function addStylesheetURL(url) {
  var link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
}
export const loadFonts = async () => {
  window.addEventListener('load', function () {
    setTimeout(async () => {
      addStylesheetURL(
        'https://fonts.googleapis.com/css2?family=Fredoka+One&family=Sniglet&display=swap'
      );
      await import('./font/cherry.css');
    }, 100);
  });
};
