export const top = /*html*/ `
<div class="flex justify-center px-10">
    <div
    id="logo"
    class="text-center w-full bg-my-yellow inline-block pt-5 pb-3 text-2xl text-navy-blue font-fredoka -top-4 relative rounded-2xl"
    >
        www.you<span class="text-red-400">test</span>.me
    </div>
</div>
`;
