export const container = document.getElementById('container');
export function timeout(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
export function getUrlParams(url) {
  let params = {};
  (url + '?')
    .split('?')[1]
    .split('&')
    .forEach(function (pair) {
      pair = (pair + '=').split('=').map(decodeURIComponent);
      if (pair[0].length) {
        params[pair[0]] = pair[1];
      }
    });
  return params;
}
